import React, { useContext, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ChessGameEvent,
  ChessGameProps,
  ChessState,
  ClientEvent,
  Color,
  initialChessState,
  move,
} from "./Data";
import { Chessboard } from "react-chessboard";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ErrorContext } from "../WithErrors";
import {
  getMyColor,
  getMyTime,
  humanTime,
  styleLatestMove,
  handleCalWidth, getSecondsToSecondsMinutesHours,
} from "./Functions";



const ChessGameComponent: React.FC<ChessGameProps> = (props) => {
  const [state, setState] = useState<ChessState>(initialChessState);
  const [dropSquareStyle, setDropSquareStyle] = useState({});

  const [selectedDate, setSelectDate] = useState(
    new Date("January 1, 1970 0:10:00")
  );
  const errorContext = useContext(ErrorContext);

  //Uses websocket closure passed as prop to mediate game event to
  async function updateGame(event: any) {
    const m: ChessGameEvent = JSON.parse(event.data);
    if (m.event === "game_state_updated") {
      setState({ ...state, ...m.data });
      const [seconds, minutes, hours] = getSecondsToSecondsMinutesHours(m.data.start_time)
      const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), hours, minutes, seconds )
      setSelectDate(newDate)
    }
    if (m.event === "game_state_update_failed") {
      errorContext.setError(m.data.error);
    }
  }

  async function handleDrop(source: string, target: string) {
    sendEvent({
      event: "game_event",
      data: {
        type: "move",
        source: source,
        target: target,
        user_id: props.player.user_id,
      },
    });
  }


  // Set up web socket connection to subscribe to game events in backend, send recieved events
  // to the rendered game
  useEffect(() => {
    if (typeof props.ws === "undefined") return;
    props.ws.addEventListener("message", updateGame);
    return () => {
      if (typeof props.ws === "undefined") return;
      props.ws.removeEventListener("message", updateGame);
    };
  }, [props.ws]);

  function sendEvent(event: ClientEvent) {
    if (typeof props.ws === "undefined") {
      errorContext.setError("can't contact back-end");
      return;
    }
    props.ws.send(JSON.stringify(event));
  }

  const handlePick = (color: Color) => {
    sendEvent({
      event: "game_event",
      data: {
        type: "pick",
        color: color,
        user_id: props.player.user_id,
        name: props.player.name,
      },
    });
  };

  const handleLeave = () => {
    sendEvent({
      event: "game_event",
      data: {
        type: "leave",
        user_id: props.player.user_id,
      },
    });
  };

  const handleStart = () => {
    sendEvent({
      event: "game_event",
      data: {
        type: "start",
        user_id: props.player.user_id,
      },
    });
  };

  const handleResign = () => {
    sendEvent({
      event: "game_event",
      data: {
        type: "resign",
        user_id: props.player.user_id,
      },
    });
  };

  const handleNewGame = () => {
    props.createNew()
  };

  useEffect(() => {
    if (typeof props.ws === "undefined") return;
    if (props.ws.readyState !== 1) return;
    if (typeof props.player === "undefined") {
      return;
    }
    if (typeof props.player.user_id === "undefined") {
      return;
    }
    sendEvent({
      event: "game_event",
      data: {
        type: "set_time",
        user_id: "init_time",
        time: [
          selectedDate.getHours(),
          selectedDate.getMinutes(),
          selectedDate.getSeconds(),
        ].join(":"),
      },
    });
  }, [props.ws]);

  const handleDateChange = (e: MaterialUiPickersDate) => {
    if (e === null) return;
    if (typeof props.player === "undefined") {
      return;
    }
    if (typeof props.player.user_id === "undefined") {
      return;
    }
    sendEvent({
      event: "game_event",
      data: {
        type: "set_time",
        user_id: props.player.user_id,
        time: [e.getHours(), e.getMinutes(), e.getSeconds()].join(":"),
      },
    });
    setSelectDate(e);
  };

  const selectTime = <MuiPickersUtilsProvider utils={DateFnsUtils}>
    
    <TimePicker
      ampm={false}
      openTo="minutes"
      views={["minutes", "seconds"]}
      format="mm:ss"
      label="Select Time"
      value={selectedDate}
      onChange={handleDateChange}
      style={{width: '8rem'}}
    />
  </MuiPickersUtilsProvider>

  const pickWhite = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handlePick("white")}
      style={{width: '1rem'}}
    >
      White
    </Button>
  );

  const pickBlack = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handlePick("black")}
      style={{width: '1rem'}}
    >
      Black
    </Button>
  );

  const startGame = (
    <Button variant="contained" color="primary" onClick={() => handleStart()}>
      Start game
    </Button>
  );

  const leaveGame = (
    <Button variant="contained" color="secondary" onClick={() => handleLeave()}>
      leave game
    </Button>
  );

  const resign = (
    <Button variant="contained" color="secondary" onClick={() => handleResign()}>
      Resign
    </Button>
  );

  const newGame = (
    <Button variant="contained" color="primary" onClick={() => handleNewGame()}>
      New Game
    </Button>
  );


  function cleanUp() {
    if (state.players === undefined) return;
    const actualUsers = state.players.filter((p) =>
      props.peers.includes({ id: p.user_id })
    );
    setState((prevState: ChessState) => {
      return { ...prevState, players: actualUsers };
    });
  }

  useEffect(() => {
    cleanUp();
  }, props.peers);

  const winnerComponent = (
    <Alert severity="info">
      {state.winner?.name} {state.score}
    </Alert>
  );

  const myColor = getMyColor(props.player.user_id, state);
  const meReady = state.state === "idle";
  const bothReady = state.players.length === 2 && state.state === "idle";
  const playing = state.state === "playing";
  const seatedColors = state.players.map((p) => p.color )

  let myTime = getMyTime(myColor, state);
  let oppTime = getMyTime(myColor === "white" ? "black" : "white", state);
  if (typeof myTime === "undefined") {
    myTime = 0;
  }
  if (typeof oppTime === "undefined") {
    oppTime = 0;
  }

  let blackTimer = (
    <Typography variant="h6" gutterBottom>
      {humanTime(state.black_time_left)}
    </Typography>
  );

  let whiteTimer = (
    <Typography color="secondary" variant="h6" gutterBottom>
      {humanTime(state.white_time_left)}
    </Typography>
  );

  // central squares get diff dropSquareStyles
  let onDragOverSquare = (square: string) => {
    setDropSquareStyle( {
      backgroundColor: "#226c00",
    }
    );
  };
  let styles = styleLatestMove(state.board.moves);
  const gameOver = ["checkmate", "draw", "over"].includes(state.state)

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const {height, width} = getWindowDimensions()

  return (
    <>
      <Grid container spacing={3}>
        <Box className="center">
          <Chessboard
            position={state.board.fen}
            onPieceDrop={(sourceSquare, targetSquare, piece ) =>
              {
                handleDrop(sourceSquare, targetSquare);
                return true;
               }
            }
            boardOrientation={myColor}
            boardWidth={handleCalWidth(width)}
            customDropSquareStyle={dropSquareStyle}
            onDragOverSquare={onDragOverSquare}
            customSquareStyles={styles}
          />
        </Box>
        <Box p={3}>
          {state.state === "idle" && selectTime}
          {gameOver && newGame}
          <Box className="button">{(seatedColors.includes("black") || (typeof myColor !== "undefined" )) || pickBlack}</Box>
          <Box className="button">{(seatedColors.includes("white") || (typeof myColor !== "undefined")) || pickWhite}</Box>
          <Box className="button">{state.score && winnerComponent}</Box>
          <Box className="button">{state.players.length === 2 && bothReady && startGame}</Box>
          <Box className="button">{myColor && meReady && leaveGame}</Box>
          <Box className="button">{playing && resign}</Box>
          {playing && <Box className="button">White's time left: {whiteTimer}</Box>}
          {playing && <Box className="button">Black's time left: {blackTimer}</Box>}
        </Box>
      </Grid>
    </>
  );
};

export { ChessGameComponent };
