import React from "react";
import axios, { AxiosInstance } from "axios";

import * as https from "https";

export interface User {
  name: string;
  id: string;
}

const httpsAgent = new https.Agent({
  rejectUnauthorized: process.env.REACT_APP_ENVIRONMENT === "development",
  keepAlive: true,
});

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
  timeout: 60000,
  httpAgent: httpsAgent,
  validateStatus: function () {
    return true;
  },
};

interface AuthClient {
  instance: AxiosInstance;
  isAuthenticated: () => Promise<User | Error>;
  signIn: (username: string) => Promise<User | Error>;
  signOut: () => void;
}
const authInstance: AxiosInstance = axios.create(axiosConfig);

authInstance.interceptors.request.use(
  function (request) {
    if (request.method === "get") {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${getToken()}`,
      };
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let authClient: AuthClient = {
  instance: authInstance,
  isAuthenticated: async () => {
    let token = getToken();
    if (token === null) {
      return new Error("no token in sessionStorage");
    }
    try {
      let response = await authClient.instance.get(
        `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_DOMAIN}:${process.env.REACT_APP_BACKEND_PORT}/auth/me`
      );
      if (response.status !== 200) {
        return new Error(`${response.data.message}`);
      }
      return response.data.user;
    } catch (e) {
      return e;
    }
  },
  signIn: async (username: string) => {
    try {
      const response = await authClient.instance.post(
        `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_DOMAIN}:${process.env.REACT_APP_BACKEND_PORT}/auth/signin`,
        { username: username }
      );
      if (response.status !== 200) {
        return new Error(`${response.data.message}`);
      }
      setToken(response.data.token);
      return response.data.user;
    } catch (e) {
      return e;
    }
  },
  signOut: () => {
    sessionStorage.removeItem(`${process.env.REACT_APP_TOKEN_NAME}`);
  },
};

const setToken = (token: string) => {
  sessionStorage.setItem(`${process.env.REACT_APP_TOKEN_NAME}`, token);
};

export const getToken = (): string | null => {
  return sessionStorage.getItem(`${process.env.REACT_APP_TOKEN_NAME}`);
};

export default authClient;
