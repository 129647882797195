export type Event = "game_state_updated" | "game_state_update_failed"

type gameType = "chess";
export type Experience = {
  name: gameType;
};

export interface Peer {
  id: string;
}

export type ChessGameProps = {
  ws: WebSocket | undefined;
  player: player;
  peers: Peer[];
  createNew: () => {};
};

export type startData = {
  user_id: string;
  type: "start";
};

export type leaveData = {
  user_id: string;
  type: "leave";
};

export type resignData = {
  user_id: string;
  type: "resign";
};

export type setTimeData = {
  user_id: string;
  time: string;
  type: "set_time";
};

export type pickData = {
  color: Color;
  user_id: string;
  type: "pick";
  name?: string;
};

export type moveData = {
  source: string;
  target: string;
  user_id: string;
  type: "move";
};

export type Error = {
  error: string;
};

export type GameState = "idle" | "playing" | "check" | "over" | "checkmate" | "draw";

export type ClientEvent = {
  data?: pickData | moveData | leaveData | startData | resignData | setTimeData;
  event: "game_event";
};

export interface ChessGameEvent {
  event: Event;
  data: ChessState & Error;
}

export type Color = "white" | "black" | undefined;

export type player = {
  user_id: string;
  name?: string;
  color?: Color;
  time_left?: number;
};

export type move = {
  from_square: string;
  to_square: string;
  color: Color;
}
//JSON representation of chess Game
export interface ChessState {
  board: {
    fen: string;
    moves: [];
  };
  white_time_left: number;
  black_time_left: number;
  next_to_move: string;
  start_time: number;
  state: GameState;
  players: player[];
  winner?: player | undefined;
  score?: string;
}

export const initialChessState: ChessState = {
  white_time_left: 300 * 1000000000,
  black_time_left: 300 * 1000000000,
  state: "idle",
  next_to_move: "white",
  start_time: 300 * 1000000000,
  players: [],
  board: {
    fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
    moves : [],
  },
};
