import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, useParams } from "react-router-dom";
import css from "./RoomComponent.module.scss";
import {apiClient, UnautharizedError} from "../clients/api-client";
import authClient, { getToken } from "../clients/auth-client";
import { ChessGameComponent } from "./games/ChessGameComponent";
import { ErrorContext } from "./WithErrors";
import { AuthContext } from "./WithAuthentication";
import history from "../router/history";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export interface User {
  name: string;
  id: string;
}

export type Room = {
  name: string;
  id?: string;
  experience?: string;
  users?: User[];
};

export interface Peer {
  id: string;
}

declare global {
  interface Window {
    cameraShare: any;
  }
}

window.cameraShare = window.cameraShare || {};
type RoomParams = {
  id: string;
};

type leftEvent = {
  id: string;
};

/// On the room, we set up web sockets for each client, that will be served as the communication
// to other people in the same room, this is for WEBrtc signalling, chat, and what not
// Websockets are passed to the game instance chosen, and the game adds event listeners
// specific for that game, coming from the game server.
//
const RoomComponent: React.FC = () => {
  const { id } = useParams<RoomParams>();
  const [room, setRoom] = useState<Room>();
  const [experienceRunning, setExperienceRunning] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [experienceName, setExperienceName] = useState<string>("Chess");
  const [websocket, setWebsocket] = useState<WebSocket>();
  const [peers, setPeers] = useState<Peer[]>([]);
  const authContext = useContext(AuthContext);
  const errorContext = useContext(ErrorContext);

  function handleLeft(data: leftEvent) {
    setPeers((peers) => peers.filter((p) => p.id != data.id));
  }

  function handleExperienceStarted() {
    setExperienceRunning(true);
  }

  function setupWebsocket(user: User, room: Room, token: string) {
    const ws = new WebSocket(
      `wss://${process.env.REACT_APP_BACKEND_DOMAIN}:${process.env.REACT_APP_BACKEND_PORT}/ws/room/${room.id}?token=${token}`
    );
    ws.onclose = function () {
      ws.send(
        JSON.stringify({
          event: "user_left",
          data: { user_id: user.id, target: null },
        })
      );
    };
    ws.onopen = function () {
      // send event so that back-end knows user joined
      ws.send(
        JSON.stringify({
          event: "user_joined",
          data: { user_id: user.id, target: null },
        })
      );
    };
    ws.onerror = function (e) {
      ws.send(
        JSON.stringify({
          event: "user_disconnected",
          data: { user_id: user.id, target: null },
        })
      );
    };
    ws.onmessage = function (evt) {
      const message = JSON.parse(evt.data);
      switch (message.event) {
        case "user_left":
          handleLeft(message.data as leftEvent);
          break;
        case "experience_started":
          handleExperienceStarted();
      }
    };
    return ws;
  }

  // Set up connection to everybody in the target room
  useEffect(() => {
    if (typeof room === "undefined") return;
    if (typeof authContext.user === "undefined") {
      errorContext.setError("undefined user or room passed");
      return;
    }
    const token = getToken();
    if (token === null) {
      errorContext.setError("no jwt token found in localStorage");
      return;
    }
    let myConn: WebSocket = setupWebsocket(authContext.user!, room, token);
    setWebsocket(myConn);
    return () => {
      myConn.close();
    };
  }, [room]);

  // Get target room based on ID from params in route
  useEffect(() => {
    async function wrapper() {
      const _room = await apiClient.getRoom(id);
      if (_room instanceof Error) {
        errorContext.setError(_room.toString());
        history.push("/room");
        return;
      }
      setRoom(_room);
    }
    wrapper();
    return () => {};
  }, []);

  // Check if experience has been started
  useEffect(() => {
    async function wrapper() {
      if (typeof room === "undefined") return;
      const running = await apiClient.experienceRunning(room);
      if (running instanceof Error) {
        errorContext.setError(running.toString());
        return;
      }
      setExperienceRunning(running);
    }
    wrapper();
    return () => {};
  }, [room]);

  function handleCameraShare() {
    errorContext.setError("WebRTC not implemented");
    window.cameraShare();
  }

  if (!window["WebSocket"]) {
    errorContext.setError("websockets not supported on your browser");
  }

  // need react router dom for real fix, temporarily use FE only
  // const joinLink = `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_DOMAIN}:${process.env.REACT_APP_BACKEND_PORT}/room/${props.room.name}`
  const joinLink = `${process.env.REACT_APP_FRONTEND_PROTOCOL}://${
    process.env.REACT_APP_FRONTEND_DOMAIN
  }:${process.env.REACT_APP_FRONTEND_PORT || ""}/${
    process.env.REACT_APP_FRONTEND_PATH || ""
  }/${id}`;

  const copyToClipBoard = () => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("value", joinLink);
    dummy.select();
    document.execCommand("copy");
    setCopied(true);
    document.body.removeChild(dummy);
  };

  const roomLink = (
    <Typography variant="h6" gutterBottom>
      <Link key="rooms" to={`/room`}>
        Visit other rooms
      </Link>
    </Typography>
  );

  const othersJoiningInfo = (
    <>
      <Typography variant="h6" gutterBottom>
        Copy joining info:
      </Typography>
      <Button
        variant="contained"
        color="primary"
        endIcon={<FileCopyIcon />}
        onClick={copyToClipBoard}
      >
        Copy URL
      </Button>
      <Typography variant="caption" display="block">
        {copied && "copied succesfully!"}
      </Typography>
    </>
  );

  const currentRoom = (
    <Typography variant="h6" gutterBottom>
      Room Name: {room ? room.name : null}
    </Typography>
  );

  const cameraShare = (
    <Typography variant="h6" gutterBottom>
      <Button variant="contained" color="primary" onClick={handleCameraShare}>
        Turn on camera
      </Button>
    </Typography>
  );

  const alphaFeatures = authContext.user!.name === "ignat";

  const handleExperienceCreate = async (
    room: Room | undefined,
    experienceName: string
  ) => {
    if (typeof room === "undefined") {
      errorContext.setError("can't create experience in empty room");
      return;
    }
    const running = await apiClient.startExperience(room, experienceName);
    if (running instanceof Error) {
      errorContext.setError(running.message);
      return;
    }
    setExperienceRunning(running);
  };
  const handleExperienceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setExperienceName(e.target.value as string);
  };

  const createExperience = (
    <>
      <TextField
        key="Experience"
        select
        onChange={handleExperienceChange}
        value={experienceName}
        style={{ marginRight: 8, verticalAlign: "middle"}}
      >
        <MenuItem value={"Chess"}>Chess</MenuItem>
      </TextField>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleExperienceCreate(room, experienceName)}
      >
        Create
      </Button>
    </>
  );

  const experienceComponent = (
    <ChessGameComponent
      ws={websocket}
      player={{
        user_id: authContext.user!.id,
        name: authContext.user!.name,
      }}
      peers={peers}
      createNew={() => handleExperienceCreate(room, experienceName)}
    />
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Box p={1}>
            {roomLink}
            {currentRoom}
            {othersJoiningInfo}
            {alphaFeatures && cameraShare}
          </Box>
          <div className={css.horizontalCenter}>
            {experienceRunning || createExperience}
          </div>
        </Grid>
        {websocket !== undefined && (
          <Grid item xs={12} sm={8}>
            {experienceRunning && experienceComponent}
          </Grid>
        )}
      </Grid>
      <video id="videoLocal" autoPlay={false} muted={false} />
      <video
        id="videoRemote"
        autoPlay={false}
        muted={false}
        style={{ display: "none" }}
      />
    </>
  );
};

const RoomsComponent: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [loadedRooms, setLoadedRooms] = useState<boolean>(false);
  const [room, setRoom] = useState<Room>();
  const errorContext = useContext(ErrorContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (loadedRooms) return;
    async function wrapper() {
      const _rooms = await apiClient.getRooms();
      if (_rooms instanceof Error) {
        errorContext.setError(_rooms.toString());
        return;
      }
      setRooms(_rooms);
      setLoadedRooms(true);
    }
    wrapper();

    return () => {};
  }, [loadedRooms]);

  const handleClick = async () => {
    if (!room) {
      errorContext.setError(`room: ${JSON.stringify(room)} is undefined`);
      return;
    }
    const roomResponse = await apiClient.createRoom(room);
    if (roomResponse instanceof UnautharizedError) {
      authContext.signout();
      return;
    };
    if (roomResponse instanceof Error) {
      errorContext.setError(roomResponse.toString());
      return;
    }
    history.push(`/room/${roomResponse.id}`);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoom({ name: e.target.value });
  };

  let roomsComponent = (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" pb={2} pt={2}>
          <Typography variant="h5">Or join an existing room below</Typography>
          <ArrowDownwardIcon color="primary" fontSize="large" />
        </Box>
      </Grid>
      {rooms.map((each) => (
        <Grid key={each.id} item sm={3} md={2} xs={12}>
          <Typography noWrap>
            <Link to={`/room/${each.id}`}>
              {each.name} {each.experience ? ` / ${each.experience}` : ""}{" "}
              {each.users ? ` / ${each.users.length}` : ""}
            </Link>
          </Typography>
        </Grid>
      ))}
    </>
  );

  let noRoomsOpen = (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        No rooms open.
      </Typography>
    </Grid>
  );

  let createForm = (
    <Grid item xs={12}>
      <form
          onSubmit={(event) => {
            event.preventDefault();
            handleClick();
          }}
      >
        <TextField
          key="name"
          label="Room name"
          style={{ margin: 8, verticalAlign: "middle" }}
          onChange={handleChange}
          value={room ? room.name : ""}
          autoFocus={true}
        />
        <Button variant="contained" color="primary" onClick={handleClick}>
          Create
        </Button>
      </form>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      {createForm}
      {rooms.length !== 0 && roomsComponent}
      {/* {rooms.length === 0 && noRoomsOpen} */}
    </Grid>
  );
};

export { RoomComponent, RoomsComponent };
