import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import css from "./WithErrors.module.scss";
interface ErrorContextType {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  errorBox: JSX.Element;
  emptyErrorBox: JSX.Element;
}

const ErrorContext = React.createContext<ErrorContextType>({
  error: "",
  setError: () => {},
  errorBox: <></>,
  emptyErrorBox: <></>,
});

const WithErrors: React.FC = (props) => {
  const [error, setError] = useState("");
  let errorsComponent = <Alert severity="error">{error}</Alert>;

  useEffect(() => {
    if (error === "") return;
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);

  const errorBox = <Box className={css.errorHolder}>{errorsComponent}</Box>;
  const emptyErrorBox = <Box className={css.errorHolder}></Box>;
  
  return (
    <ErrorContext.Provider
      value={{
        error: error,
        setError: setError,
        errorBox: errorBox,
        emptyErrorBox: emptyErrorBox,
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
};

export { WithErrors, ErrorContext };
