import React from "react";
import axios, { AxiosInstance } from "axios";

import * as https from "https";
import authClient, { getToken } from "./auth-client";

export interface User {
  name: string;
  id: string;
}

export type Room = {
  name: string;
  id?: string;
  experience?: string;
  users?: User[];
};

const API_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_DOMAIN}:${process.env.REACT_APP_BACKEND_PORT}`;



const httpsAgent = new https.Agent({
  rejectUnauthorized: process.env.REACT_APP_ENVIRONMENT === "development",
  keepAlive: true,
});

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
  timeout: 60000,
  httpAgent: httpsAgent,
  validateStatus: function () {
    return true;
  },
};

interface ApiClient {
  instance: AxiosInstance;
  createRoom: (room: Room) => Promise<Room | Error>;
  startExperience: (
    room: Room,
    experienceName: string
  ) => Promise<boolean | Error>;
  experienceRunning: (room: Room) => Promise<boolean | Error>;
  getRoom: (id: string) => Promise<Room | Error>;
  getRooms: () => Promise<Room[] | Error>;
}
const apiInstance: AxiosInstance = axios.create(axiosConfig);

apiInstance.interceptors.request.use(
  function (request) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${getToken()}`,
    };
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class UnautharizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnautharizedError";
  }
}

let apiClient: ApiClient = {
  instance: apiInstance,

  createRoom: async (room: Room) => {
    try {
      const params = {
        name: room.name,
      };
      const response = await apiClient.instance.post(`${API_URL}/room`, params);
      if (response.status == 401) {
        return new UnautharizedError(`${response.data.message}`);
      }
      if (response.status != 200) {
        return new Error(`${response.data.message}`);
      }
      return response.data;
    } catch (e) {
      return e
    }
  },

  startExperience: async (room: Room, experienceName: string) => {
    const params = {
      game: experienceName.toLowerCase(),
    };
    const response = await apiClient.instance.post(
      `${API_URL}/room/${room.id}`,
      params
    );
    if (response.status == 401) {
      return new UnautharizedError(`${response.data.message}`);
    }
    if (response.status != 200) {
      return new Error(`${response.data.message}`);
    }
    return response.data.running;
  },

  experienceRunning: async (room: Room) => {
    const response = await apiClient.instance.get(
      `${API_URL}/room/${room.id}/experience`
    );
    if (response.status != 200) {
      return new Error(`${response.data.message}`);
    }
    return response.data.running;
  },

  getRoom: async (id: string): Promise<Room | Error> => {
    const response = await apiClient.instance.get(`${API_URL}/room/${id}`);
    if (response.status != 200) {
      return new Error(`${response.data.message}`);
    }
    return response.data as Room;
  },

  getRooms: async ():Promise<Room[] | Error> => {
    const response = await apiClient.instance.get(`${API_URL}/room`);
    if (response.status != 200) {
      return new Error(`${response.data.message}`);
    }
    return response.data.rooms as Room[];
  },
};

export {
  apiClient,
  UnautharizedError,
};
