import { ChessState, Color, move } from "./Data";

export function padToTwo(number: number): string {
  let ret = number.toString();
  if (number <= 9) {
    ret = ("0" + number.toString()).slice(-2);
  }
  return ret;
}

export function getMyColor(id: string, state: ChessState): Color {
  for (const p of state.players.values()) {
    if (p.user_id === id) {
      return p.color;
    }
  }
  return;
}

export function getMyTime(color: Color, state: ChessState): number | undefined {
  if (state.players === null) {
    return;
  }
  if (state.players === undefined) {
    return;
  }
  if (color === "white") {
    return state.white_time_left;
  }
  if (color === "black") {
    return state.black_time_left;
  }
  return;
}

export const getSecondsToSecondsMinutesHours: (ns: number) => number[] = (ns: number) => {
  const seconds = Math.floor(ns / 1000000000);
  const hours = Math.floor(seconds / 3600);
  const seconds_after_hours = seconds - hours * 3600;
  const minutes = Math.floor(seconds_after_hours / 60);
  const seconds_after_minutes = seconds_after_hours - minutes * 60
  return [seconds_after_minutes, minutes, hours]
};

export const humanTime: (ns: number) => string = (ns: number) => {
  const seconds = Math.floor(ns / 1000000000);
  const hours = Math.floor(seconds / 3600);
  const seconds_after_hours = seconds - hours * 3600;
  const minutes = Math.floor(seconds_after_hours / 60);
  const seconds_after_minutes = padToTwo(seconds_after_hours - minutes * 60);
  return `${padToTwo(hours)}:${padToTwo(minutes)}:${seconds_after_minutes}`;
};

export function styleLatestMove(moves: move[] | undefined) {
  if (typeof moves === "undefined") {
    return {};
  }
  if (moves.length === 0) {
    return {};
  }
  let obj = {
    [moves[moves.length - 1].from_square]: {
      backgroundColor: "rgba(255, 255, 0, 0.4)",
    },
    [moves[moves.length - 1].to_square]: {
      backgroundColor: "rgba(255, 255, 0, 0.4)",
    },
  };
  return obj;
}

export function handleCalWidth(width: number) {
  if (width <= 600) {
    return width - 40;
  }
  if (width <= 768) {
    return 0.5 * width - 40;
  }
  return 0.4 * width - 40;
}
