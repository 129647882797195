import React, {
  useEffect,
  useState,
  ChangeEvent,
  SyntheticEvent,
  useContext,
} from "react";
import authClient from "../clients/auth-client";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import css from "./WithAuthentication.module.scss";
import history from "../router/history";
import { ErrorContext } from "./WithErrors";
import { AppBar } from "./AppBar";


export interface User {
  name: string;
  id: string;
}

interface AuthContextType {
  signout: () => void;
  user?: User;
}

const initialContext = {
  signout: () => {
    authClient.signOut();
    history.push("/");
  },
};

export const AuthContext = React.createContext<AuthContextType>(initialContext);

// Auth lets user authenticate to recieve a JWT that has the claims to visit
// the Room page
const WithAuthentication: React.FC = (props) => {
  const [authUser, setAuthUser] = useState<User | undefined>();
  const [finishedAuthentication, setFinishedAuthentication] = useState(false);
  const [userName, setUserName] = useState("");
  const errorContext = useContext(ErrorContext);

  useEffect(() => {
    if (finishedAuthentication) return;
    async function wrapper(): Promise<void> {
      const user = await authClient.isAuthenticated();
      if (user instanceof Error) {
        errorContext.setError(user.toString());
        setAuthUser(undefined);
        setFinishedAuthentication(true);
        return;
      }
      setAuthUser(user);
      setFinishedAuthentication(true);
    }
    wrapper();
    return () => {
      errorContext.setError("");
    };
  }, [finishedAuthentication]);

  function handleUserNameForm(event: ChangeEvent<HTMLInputElement>): void {
    setUserName(event.target.value);
  }

  async function handleLogin(event: SyntheticEvent) {
    event.preventDefault();
    const user = await authClient.signIn(userName);
    if (user instanceof Error) {
      errorContext.setError(user.toString());
      return;
    }
    setAuthUser(user);
    setFinishedAuthentication(true);
    history.push("/");
  }

  const signinComponent: React.ReactNode = (
    <form onSubmit={handleLogin}>
      <TextField
        key="username"
        label="Your name"
        style={{ margin: 8, verticalAlign: "middle" }}
        onChange={handleUserNameForm}
        value={userName}
      />
      <Button variant="contained" color="primary" onClick={handleLogin}>
        join
      </Button>
    </form>
  );

  function handleLogout() {
    authClient.signOut();
    setAuthUser(undefined);
    setFinishedAuthentication(false);
    history.push("/");
  }

  let showChildren = authUser && finishedAuthentication;
  let showSignin = (typeof authUser === "undefined") && finishedAuthentication;

  return (
    <AuthContext.Provider value={{ signout: handleLogout, user: authUser }}>
      <AppBar />
      {errorContext.error ? errorContext.errorBox : errorContext.emptyErrorBox}
      <Box className="centerHolder">{showSignin && signinComponent}</Box>
      <Box className={css.authContainer}>{showChildren && props.children}</Box>
    </AuthContext.Provider>
  );
};

export { WithAuthentication };
