import React from "react";
import { RoomComponent, RoomsComponent } from "./components/RoomComponent";
import { WithAuthentication } from "./components/WithAuthentication";
import { Router, Switch, Route } from "react-router-dom";
import "../src/styles/app.scss";
import history from "./router/history";
import { LandingPage } from "./components/LandingPage";
import { WithErrors } from "./components/WithErrors";

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <WithErrors>
          <WithAuthentication >
              <Route exact path="/room/:id">
                <RoomComponent />
              </Route>
              <Route  exact path="/room">
                <RoomsComponent />
              </Route>
              <Route  exact path="/home" >
                <LandingPage />
              </Route>
              <Route  exact path="/" >
                <RoomsComponent />
              </Route>
          </WithAuthentication>
        </WithErrors>
      </Switch>
    </Router>
  );
};

export default App;
