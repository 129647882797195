import React from "react";
import { Link } from "react-router-dom";

const LandingPage: React.FC = (props) => {
  return (
    <>
      <div className="centerHolder">
        Visit our rooms to play some games: <Link to={"/room"}>Rooms</Link>
      </div>
    </>
  );
};

export { LandingPage };
