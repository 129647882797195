import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./WithAuthentication";
import css from "./AppBar.module.scss";
import Button from "@material-ui/core/Button";
import {ErrorContext} from "./WithErrors";

const AppBar: React.FC = () => {
  const authContext = useContext(AuthContext);
  const errContext = useContext(ErrorContext);
  const signoutComponent: React.ReactNode = (
    <Button variant="contained" color="default" onClick={authContext.signout}>
      sign out
    </Button>
  );

  return (
    <>
      <div className={css.appBar}>
        <div className={css.appBarContainer}>
          <div className={css.logoContainer}>
            <Link to={"/"}>
              <img className={css.appBarLogo} src="/favicon.ico" alt="" />
            </Link>
          </div>
          <div className={css.signOutBtn}>
            {authContext.user && signoutComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export { AppBar };
